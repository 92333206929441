import api from "../../../../../common/api";

export async function applicationsLoader() {
  try {
    const response = await api.applications.getApplications();
    return response;
  } catch (err) {
    console.log(err);
  }
}
