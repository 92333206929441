import { message, type FormProps } from "antd";
import { RuleObject } from "antd/es/form";
import React, { FC, createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import api from "../../../../../common/api";
import { Form } from "antd/lib";
import useUser from "../../../../../hooks/useUser";

export type FieldType = {
  first_name: string;
  email: string;
  phone_number: string;
  otp: string;
  password: string;
  confirm_password: string;
  remember: string;
};

const Context = () => {
  const [form] = Form.useForm<FieldType>();
  const [isSentSms, setIsSentSms] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState(false);
  const [otpId, setOtpId] = useState("");

  const { setAuth } = useAuth();
  const { setUser } = useUser();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    try {
      const response = await api.account.signup({
        first_name: values.first_name,
        email: values.email,
        phone_number: values.phone_number,
        password: values.password,
      });
      if (response.data.token) {
        setAuth(response.data.token);
        setUser(response.data);
        message.success(t("auth.signup.account_created"));
        navigate("/account/personal-info");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };

  const validatePassword = (rule: RuleObject, value: string): Promise<void> => {
    if (!value) {
      return Promise.reject(t("auth.signup.validation_password"));
    } else if (value.length < 6) {
      return Promise.reject(t("auth.signup.validation_password_least"));
    } else {
      return Promise.resolve();
    }
  };

  const validateConfirmPassword = ({ getFieldValue }: any) => ({
    validator(_: RuleObject, value: string): Promise<void> {
      if (!value) {
        return Promise.reject(
          t("auth.signup.validation_confirmation_password")
        );
      } else if (getFieldValue("password") !== value) {
        return Promise.reject(
          t("auth.signup.validation_confirmation_password_not_match")
        );
      } else {
        return Promise.resolve();
      }
    },
  });

  const sendOtp = async () => {
    const phone_number = form.getFieldValue("phone_number");
    try {
      const response = await api.otp.sendOtp({ phone_number });
      setOtpId(response.data);
      setIsSentSms(true);
      message.success(t("auth.signup.sms_sent"));
    } catch (err: any) {
      message.error(err);
    }
  };

  const checkOtp = async (code: number) => {
    try {
      const response = await api.otp.checkOtp({ _id: otpId, code });
      return true;
    } catch (err: any) {
      return false;
    }
  };

  const checkPhoneNumber = async (phone_number: string) => {
    try {
      const response = await api.account.checkPhonenumber({ phone_number });
      return true;
    } catch (err) {
      return false;
    }
  };

  return {
    state: { isSentSms, phoneValidation, form },
    actions: {
      onFinish,
      onFinishFailed,
      validatePassword,
      validateConfirmPassword,
      sendOtp,
      setPhoneValidation,
      checkPhoneNumber,
      checkOtp,
    },
  };
};

const SignupContext = createContext<any>({ state: {}, actions: {} });

export const SignupContextProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const value = Context();
  return (
    <SignupContext.Provider value={value}>{children}</SignupContext.Provider>
  );
};

export default function useSignupContext() {
  return useContext<ReturnType<typeof Context>>(SignupContext);
}
